import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { kebabCase } from 'lodash'
import { Layout, Listing, WrapperTwo, Title, SEO, Header } from '../components'
import website from '../../config/website'

const Hero = styled.header`
  background-color: ${props => props.theme.colors.primary};
  padding-top: 1rem;
  padding-bottom: 2rem;
  color: ${props => props.theme.colors.white};
  div {
    padding-top: 3em;
  }
  h1 {
    color: ${props => props.theme.colors.white};
  }
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.greyBlue};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`
const StyledWrapperTwo = styled(WrapperTwo)`
  min-height: calc(100vh - 460px);
  padding: 0 8em;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    min-height: calc(100vh - 410px);
  }
`
const CatWrapper = StyledWrapperTwo.withComponent('main')
const CategoryNav = styled(Link)`
  padding: 0 0.5em;
  color: ${props => props.theme.colors.orangeLighter};
  :hover {
    color: ${props => props.theme.colors.white};
  }
`
const Category = ({
  pageContext: { category },
  data: {
    posts: { edges, totalCount },
    categories,
  },
  location,
}) => (
  <Layout>
    <SEO title={`Category: ${category} | ${website.titleAlt}`} pathname={location.pathname} />
    <Header invert />
    <Hero>
      <WrapperTwo>
        <Headline>Category:</Headline>
        <h1>{category}</h1>
        {categories.edges.map(cat => (
          <CategoryNav
            activeStyle={{ color: 'white' }}
            key={cat.node.data.name}
            to={`/categories/${kebabCase(cat.node.data.name)}`}
          >
            {cat.node.data.name}
          </CategoryNav>
        ))}
      </WrapperTwo>
    </Hero>
    <CatWrapper>
      <Title style={{ marginTop: '4rem', color: 'black' }}>
        These are {totalCount} selected {category} {totalCount === 1 ? 'Project' : 'Projects'}{' '}
      </Title>
      <Listing posts={edges} />
    </CatWrapper>
  </Layout>
)

export default Category

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    posts: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query CategoryPage($category: String!) {
    categories: allPrismicCategory {
      edges {
        node {
          data {
            name
          }
        }
      }
    }
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          categories: { elemMatch: { category: { document: { elemMatch: { data: { name: { eq: $category } } } } } } }
        }
      }
    ) {
      totalCount
      edges {
        node {
          uid
          data {
            title {
              text
            }
            thumbnail {
              small {
                url
              }
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
